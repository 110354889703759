<<<<<<< HEAD
<template>
  <div class="home-container">
    <div class="bgc">
      <div class="title">
        <div class="heartitle">
          <img
            class="companylogin"
            src="../../assets/首页切图/2-logo@2x.png"
            alt=""
          />
          <img
            class="positioning"
            @click="$router.push('/map')"
            src="../../assets/首页切图/dingwei@3x.png"
            alt=""
          />
          <img
            class="map"
            @click="$router.push('/map')"
            src="../../assets/首页切图/ditu@3x.png"
            alt=""
          />
          <div class="titleright">
            <el-input
              class="input"
              placeholder="搜索"
              color="#F3AA19;"
              v-model="queryInfo.resName"
              @keyup.enter.native="onSearch"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <!-- 登录 -->
            <!--            <div class="mylogin">-->
            <!--              <el-dropdown>-->
            <!--                <div class="block">-->
            <!--                  <el-avatar size="large" :src="circleUrl"></el-avatar>-->
            <!--                </div>-->
            <!--                <span class="logintext">请登录</span>-->
            <!--                &lt;!&ndash; 下拉框 &ndash;&gt;-->
            <!--                <el-dropdown-menu slot="dropdown" size="medium">-->
            <!--                  <el-dropdown-item-->
            <!--                    icon="el-icon-view-->
            <!--"-->
            <!--                    >浏览记录</el-dropdown-item-->
            <!--                  >-->
            <!--                  <el-dropdown-item-->
            <!--                    icon="el-icon-star-off-->
            <!--"-->
            <!--                    >我的收藏</el-dropdown-item-->
            <!--                  >-->
            <!--                  <el-dropdown-item-->
            <!--                    icon="el-icon-switch-button-->
            <!--"-->
            <!--                    >退出登录</el-dropdown-item-->
            <!--                  >-->
            <!--                </el-dropdown-menu>-->
            <!--              </el-dropdown>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="center">
          <div class="centerrow">
            <img
              class="figure"
              src="../../assets/首页切图/xiezideren@3x.png"
              alt=""
            />
            <!-- 历史人物 -->
            <div
              class="fristmodule"
              @click="
                toplayInfo(
                  '260125268738580480',
                  '260125268776599552',
                  '历史人物'
                )
              "
            >
              <el-image
                class="fristmoduleimg"
                :src="url + list[6].resList[0].imgUrl"
                alt=""
                lazy
              ></el-image>
              <!-- <img class="fristmoduleimg" :src="url + list[6].resList[0].imgUrl" alt="" /> -->
              <div class="fristtext">
                <span class="titles">{{ list[6].title }}</span>
              </div>
              <img
                class="playbtn"
                src="../../assets/首页切图/playbtn.png"
                alt=""
              />
            </div>
            <!-- banner图 -->
            <div class="twomodule">
              <el-carousel :interval="5000" arrow="always">
                <el-carousel-item
                  v-for="(item, index) in bannerlist"
                  :key="index"
                >
                  <!-- <h3>{{ item }}</h3> -->
                  <img class="bannerimg" :src="url + item.imgUrl" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 八大模块 -->
            <div class="threemodule">
              <img
                class="eight"
                src="../../assets/首页切图/2-eight.png"
                alt=""
              />
              <div class="eighttext">
                <div
                  class="eighttexts"
                  v-for="item in classList"
                  :key="item.id"
                >
                  <span @click="openfrist(item)">{{ item.title }}</span>
                </div>
                <img
                  @click="openmore"
                  class="videobtn"
                  src="../../assets/首页切图/videobutton.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="centerbot">
            <img
              class="playbot"
              @click="hotInfo(hotlist[0])"
              src="../../assets/首页切图/playbtn.png"
              alt=""
            />
            <img
              class="hotimg"
              src="../../assets/首页切图/hot@2x_79.png"
              alt=""
            />
            <!-- 热门推荐 -->
            <div class="boxone" @click="hotInfo()">
              <!-- <el-image class="boximg" :src="this.hotimg" lazy></el-image> -->
              <img class="boximg" :src="hotimg" alt="" />
            </div>
            <!-- 一带一路 -->
            <div
              class="boxtwo"
              @click="
                toplayInfo(
                  '260125270852509696',
                  '260125270890528768',
                  '一带一路'
                )
              "
            >
              <img
                class="playtwo"
                src="../../assets/首页切图/playbtn.png"
                alt=""
              />
              <img
                class="boxtwoimg"
                :src="url + list[7].resList[0].imgUrl"
                alt=""
              />
              <div class="boxtext">
                <span>{{ list[7].title }}</span>
              </div>
            </div>
            <!-- 革命英雄 -->
            <div
              class="boxthree"
              @click="
                toplayInfo(
                  '260125264988872704',
                  '260125265039474688',
                  '革命英雄'
                )
              "
            >
              <img
                class="playthree"
                src="../../assets/首页切图/playbtn.png"
                alt=""
              />
              <div class="slot"></div>
              <span class="new"> 最新</span>

              <img
                class="boxthreeimg"
                :src="url + list[5].resList[0].imgUrl"
                alt=""
              />
              <div class="boxtexts">
                <span>{{ list[5].title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部英雄人物 -->
      <div class="bottom">
        <img class="line" src="../../assets/首页切图/line (2).png" alt="" />
        <div
          class="historical"
          @click="openbotlist('260125268738580480', '历史人物')"
        >
          <img src="../../assets/首页切图/lishirenwu@3x.png" alt="" />
        </div>
        <div
          class="altoStory"
          id="260125237222580224"
          @click="openbotlist('260125237222580224', '典故故事')"
        >
          <img src="../../assets/首页切图/diangugushi@3x.png" alt="" />
        </div>
        <div
          class="revolutionary"
          id="260125264988872704"
          @click="openbotlist('260125264988872704', '革命英雄')"
        >
          <img src="../../assets/首页切图/geminggushi@3x.png" alt="" />
        </div>
        <div
          class="classictionary"
          id="260125225902153728"
          @click="openbotlist('260125225902153728', '经典故事')"
        >
          <img src="../../assets/首页切图/jingdiangushi@3x.png" alt="" />
        </div>
        <div
          class="ancient"
          id="260125246395523072"
          @click="openbotlist('260125246395523072', '历史故事')"
        >
          <img src="../../assets/首页切图/lishigushi@3x.png" alt="" />
        </div>
        <div
          class="oneBelt"
          id="260125270852509696"
          @click="openbotlist('260125270852509696', '一带一路')"
        >
          <img src="../../assets/首页切图/yidaiyilu@3x.png" alt="" />
        </div>
        <div
          class="rolemodel"
          id="260125252359823360"
          @click="openbotlist('260125252359823360', '榜样人物')"
        >
          <img src="../../assets/首页切图/bangyangrenwu@3x.png" alt="" />
        </div>
        <div
          class="smartStories"
          id="260125261838950400"
          @click="openbotlist('260125261838950400', '智慧故事')"
        >
          <img src="../../assets/首页切图/zhihuigushi@3x.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getClassify,
  getRecommend,
  getBanner,
  queryRes,
  addAccessStatistics,
} from "@/api/home";
import { urlLogin } from "@/api/login";
import Common from "@/components/comm/index";
import { test } from "@/api/test";
import qs from "qs";
import store from "@/store/index";

export default {
  name: "home",
  components: {},
  props: {},
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      url: Common.getUrl(),
      value: "",
      list: [],
      classList: [],
      hotlist: [],
      hotimg: "",
      id: "",
      tokenAuth: "",
      bannerlist: [],
      queryInfo: {
        resName: "", // 关键字
        pageNo: 1, //页数
        pageSize: 18, //页容量
      },
      searchlist: [],
      arrId: [],
      title: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.datalist = this.list;
    this.list = test();
    // if (!this.$Cookies.get("customerId")) {
    //   this.urlLogins();
    // }else{
    this.getClassifys();
    this.getBanners();
    this.getRecommends();
    this.addAccessStatistics();
    // }
  },
  mounted() {},
  methods: {
    async addAccessStatistics() {
      let data = {
        terminal: 1,
        content: "首页",
      };
      const { data: res } = await addAccessStatistics(qs.stringify(data));
    },
    onSearch(val) {
      queryRes(qs.stringify(this.queryInfo)).then((res) => {
        // console.log(res.data.data.records);
        this.searchlist = res.data.data.records;
        this.queryInfo.pageNo = res.data.data.pages;
        this.queryInfo.pageSize = res.data.data.size;

        if (res.data.code === 200) {
          // this.$router.push('/listpage')
          this.$router.push({
            path: "/listpage",
            query: {
              resName: this.queryInfo.resName,
            },
          });
        }
      });
    },
    // 获取url参数值
    getQueryString(param) {
      const reg = new RegExp("(^|&)" + param + "=([^&]*)(&|$)");
      const r =
        window.location.search.substr(1).match(reg) ||
        window.location.hash
          .substring(window.location.hash.search(/\?/) + 1)
          .match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
    },
    // 登录和ip链接
    // ?tokenAuth=MjUyODYxMTI3ODcxMDQxNTM2Y3hjdzIwMjAx
    urlLogins() {
      if (this.getQueryString("tokenAuth")) {
        this.tokenAuth = this.getQueryString("tokenAuth");
      }
      var data = this.$qs.stringify({ tokenAuth: this.tokenAuth });
      urlLogin(data).then((res) => {
        if (res.data.code === 200) {
          this.$Cookies.set(
            "customerId",
            this.$JSONBig.stringify(res.data.data)
          );
          this.getClassifys();
          this.getBanners();
          this.getRecommends();
        }
        if (res.data.code === 500) {
         
          this.$router.push("/login");
        }
        if (res.data.code === 600) {
          this.$router.push("/login");
        }
      });
      // .then(() => {
      //   this.getClassifys();
      //   this.getBanners();
      // });
    },
    getBanners() {
      getBanner().then((res) => {
        this.bannerlist = res.data.data.banners;
      });
    },
    // 首页分类
    async getClassifys() {
      const { data: res } = await getClassify();
      this.classList = res.data.functions;
    },
    // 热门推荐
    async getRecommends() {
      const { data: res } = await getRecommend();
      if (res.code === 200) {
        this.hotlist = res.data.resourcesList;
        this.hotimg = this.url + this.hotlist[0].imgUrl;
      }
    },
    openfrist(item) {
      console.log(item);
      this.$router.push({
        path: "/listpage",
        query: {
          functionId: item.id,
          title: item.title,
        },
      });
    },
    // 分类更多（八大模块）
    openmore() {
      this.$router.push({
        path: "/listpage",
        query: {
          functionId: this.classList[this.classList.length - 1].id,
          title: "八大视频模块",
        },
      });
    },
    toplayInfo(item, item2, title) {
      this.$router.push({
        path: "/videoPlay",
        query: {
          functionId: item,
          resId: item2,
          title: title,
        },
      });
    },
    openbotlist(item, item2) {
      this.$router.push({
        path: "/listpage",
        query: {
          functionId: item,
          title: item2,
        },
      });
    },
    hotInfo() {
      this.$router.push({
        path: "/videoPlay",
        query: {
          resId: this.hotlist[0].resId,
          functionId: 0,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.bgc {
  height: 1080px;
  width: 1920px;
  background: url("../../assets/首页切图/2-bgc.png") no-repeat;
  background-size: cover;
}
.title {
  height: 678px;
  width: 1200px;
  margin: 0 auto;
}
.companylogin {
  width: 160px;
  height: 60px;
  margin-top: -30px;
}
.heartitle {
  position: relative;
  height: 100px;
  width: 100%;
  padding-top: 23px;
  .positioning {
    position: absolute;
    top: 62%;
    left: 29.5%;
    animation: myfirst 2s infinite;
    width: 20px;
    height: 29px;
  }
  @keyframes myfirst {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(0px, -10px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
}
.map {
  margin-left: 10%;
  margin-top: 1%;
  width: 156px;
  height: 95px;
}
.titleright {
  position: absolute;
  top: 37px;
  right: 0px;
  width: 220px;
  height: 84px;
}
/deep/input::-webkit-input-placeholder {
  color: #f3aa19;
  font-weight: 400;
  font-size: 16px;
}
/deep/input::-moz-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
/deep/input::-ms-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
.input {
  width: 180px;
  height: 46px;
  opacity: 0.52;
  /deep/.el-icon-search:before {
    padding-left: 20px;
    color: #f3aa19;
    font-size: 18px;
    line-height: 46px;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: #f3aa19;
    line-height: 47px;
    padding-left: 40px;
  }
}
.mylogin {
  position: absolute;
  top: 0;
  right: -35px;
  width: 48px;
  height: 85px;
  .myimg {
    width: 46px;
    height: 46px;
  }
  .logintext {
    width: 48px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }
}
.center {
  position: relative;
  width: 100%;
  height: 629px;
  margin-top: 30px;
}
.centerrow {
  position: relative;
  width: 100%;
  height: 361px;
  .figure {
    position: absolute;
    top: -7.5%;
    left: 5%;
    width: 196px;
    height: 116px;
  }
  .fristmodule {
    position: absolute;
    top: 14%;
    left: 0;
    width: 292px;
    height: 250px;
    border-radius: 24px;
    border: 5px solid #fff;
    .fristmoduleimg {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 282px;
      height: 240px;
      border-radius: 17px;
    }
    .fristtext {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 36px;
      width: 100%;
      background-color: #e04f00;
      text-align: center;
      border-radius: 0 0 17px 17px;
      .titles {
        height: 36px;
        width: 100%;
        color: #fff;
        line-height: 36px;
        font-size: 16px;
      }
    }
    .playbtn {
      position: absolute;
      top: 33%;
      left: 43%;
      width: 54px;
      height: 55px;
    }
  }
  .twomodule {
    position: absolute;
    top: 14%;
    left: 26%;
    width: 500px;
    height: 250px;
    border-radius: 24px;
    border: 5px solid #fff;
  }
  /deep/.el-carousel__item,
  .el-carousel--horizontal {
    border-radius: 19px;
  }
  .bannerimg {
    width: 100%;
    height: 100%;
    border-radius: 19px;
  }
  /deep/.el-carousel__container {
    height: 240px;
  }
  .threemodule {
    position: absolute;
    top: 14%;
    right: 0;
    width: 372px;
    height: 236px;
    .eight {
      width: 349px;
      height: 309px;
      margin-top: -63px;
      margin-left: 9px;
    }
    .eighttext {
      position: absolute;
      top: 60px;
      left: 14%;
      width: 267px;

      .eighttexts {
        display: inline-block;
        width: 87px;
      }
      span {
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        font-weight: 400;
      }
      .eighttexts:nth-child(1) {
        color: #db8800;
      }
      .eighttexts:nth-child(1):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(2) {
        color: #36dd01;
      }
      .eighttexts:nth-child(2):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(3) {
        color: #d62800;
      }
      .eighttexts:nth-child(3):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(4) {
        color: #00cfe0;
      }
      .eighttexts:nth-child(4):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(5) {
        color: #dd007c;
      }
      .eighttexts:nth-child(5):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(6) {
        color: #00deba;
      }
      .eighttexts:nth-child(6):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(7) {
        color: #003ce5;
      }
      .eighttexts:nth-child(7):hover {
        color: black;
        font-size: 18px;
      }
      .eighttexts:nth-child(8) {
        color: #7400df;
      }
      .eighttexts:nth-child(8):hover {
        color: black;
        font-size: 18px;
      }
      .videobtn {
        width: 179px;
        height: 45px;
        text-align: center;
        margin: 8px 25%;
      }
    }
  }
}
.centerbot {
  position: relative;
  width: 100%;
  height: 241px;
  margin-top: -40px;
  .playbot {
    position: absolute;
    top: 100px;
    left: 12%;
    width: 54px;
    height: 55px;
    z-index: 99999;
  }
  .hotimg {
    width: 100px;
    height: 98px;
    position: absolute;
    left: -110px;
    top: 26px;
  }
  .boxone {
    position: relative;
    width: 341px;
    height: 241px;
    background: url("../../assets/首页切图/kuang.png") no-repeat;
    background-size: 100% 100%;
    .boximg {
      position: absolute;
      top: 11%;
      left: 8%;
      width: 276px;
      height: 187px;
      border-radius: 23px;
    }
  }
  .boxtwo {
    position: absolute;
    top: 0;
    left: 30%;
    width: 420px;
    height: 241px;
    border-radius: 24px;
    border: 5px solid #fff;
    .playtwo {
      position: absolute;
      top: 80px;
      left: 43%;
      width: 54px;
      height: 55px;
      z-index: 99999;
    }
    .boxtwoimg {
      width: 100%;
      height: 194px;
      border-radius: 17px 17px 0 0;
    }
    .boxtext {
      height: 36px;
      color: #fff;
      background-color: #0090c5;
      text-align: center;
      border-radius: 0 0 17px 17px;
      line-height: 36px;
      font-size: 16px;
    }
  }
  .boxthree {
    position: absolute;
    top: 0;
    left: 67%;
    width: 398px;
    height: 241px;
    border-radius: 24px;
    border: 5px solid #fff;
    .playthree {
      position: absolute;
      top: 80px;
      left: 43%;
      width: 54px;
      height: 55px;
      z-index: 99999;
    }
    .slot {
      position: absolute;
      top: 0;
      right: 0;
      height: 55px;
      border: 30px solid #f42328;
      border-left: 32px solid transparent;
      border-top: 32px solid transparent;
      border-bottom-right-radius: 20px;
      content: "";
      width: 20px;
      transform: rotate(270deg);
    }
    .new {
      position: absolute;
      top: 9px;
      right: 2px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      transform: rotate(38deg);
    }
    .boxthreeimg {
      width: 100%;
      height: 194px;
      border-radius: 17px 17px 0 0;
    }
    .boxtexts {
      height: 36px;
      color: #fff;
      background-color: #c20000;
      text-align: center;
      border-radius: 0 0 17px 17px;
      line-height: 36px;
      font-size: 16px;
    }
  }
}
.bottom {
  position: relative;
  width: 100%;
  height: 130px;
  bottom: 0;
  margin-top: 200px;
  .line {
    margin-left: 17%;
    margin-top: 50px;
    width: 1317px;
    height: 87px;
  }
  .historical {
    position: absolute;
    top: -20%;
    left: 12%;
    img {
      width: 112px;
      height: 185px;
    }
  }
  .historical:hover {
    position: absolute;
    top: -14%;
    left: 12%;
    img {
      width: 112px;
      height: 185px;
    }
  }
  .altoStory {
    position: absolute;
    top: -28%;
    left: 18%;
    img {
      width: 219px;
      height: 188px;
    }
  }
  .altoStory:hover {
    position: absolute;
    top: -22%;
    left: 18%;
    img {
      width: 219px;
      height: 188px;
    }
  }
  .revolutionary {
    position: absolute;
    top: -2%;
    left: 29%;
    img {
      width: 209px;
      height: 192px;
    }
  }
  .revolutionary:hover {
    position: absolute;
    top: -17%;
    left: 29%;
    img {
      width: 209px;
      height: 192px;
    }
  }
  .classictionary {
    position: absolute;
    top: -21%;
    left: 42%;
    img {
      width: 101px;
      height: 195px;
    }
  }
  .classictionary:hover {
    position: absolute;
    top: -15%;
    left: 42%;
    img {
      width: 101px;
      height: 195px;
    }
  }
  .ancient {
    position: absolute;
    top: -28%;
    left: 50%;
    img {
      width: 171px;
      height: 199px;
    }
  }
  .ancient:hover {
    position: absolute;
    top: -21%;
    left: 50%;
    img {
      width: 156px;
      height: 186px;
    }
  }
  .oneBelt {
    position: absolute;
    top: -25%;
    left: 64%;
    img {
      width: 156px;
      height: 186px;
    }
  }
  .oneBelt:hover {
    position: absolute;
    top: -22%;
    left: 64%;
    img {
      width: 156px;
      height: 186px;
    }
  }
  .rolemodel {
    position: absolute;
    top: -10%;
    left: 75%;
    img {
      width: 141px;
      height: 149px;
    }
  }
  .rolemodel:hover {
    position: absolute;
    top: -2%;
    left: 75%;
    img {
      width: 141px;
      height: 149px;
    }
  }
  .smartStories {
    position: absolute;
    top: 9%;
    left: 83.5%;
    img {
      width: 287px;
      height: 105px;
    }
  }
  .smartStories:hover {
    position: absolute;
    top: 15%;
    left: 83.5%;
    img {
      width: 287px;
      height: 105px;
    }
  }
}
</style>